import { Observer, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, flatMap, take, retry, switchMap, tap } from 'rxjs/operators';
import * as AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import { ClientConfiguration } from 'aws-sdk/clients/s3';
import { Store, select } from '@ngrx/store';

import { CognitoUtil } from './cognito.service';
import * as fromRoot from './../reducers';
import { environment } from './../../environments/environment';
import { TenantUtil } from '../shared/util/tenant-util';

/**
 * S3にファイルをアップロードするサービス
 *
 * @export
 * @class S3Service
 */
@Injectable({
  providedIn: 'root'
})
export class S3Service {
  jwt$: Observable<string>;
  /**
   * Creates an instance of S3Service.
   * @param {CognitoUtil} cognitoUtil
   * @memberof S3Service
   */
  constructor(
    private cognitoUtil: CognitoUtil,
    private tenantUtil: TenantUtil,
    private store: Store<fromRoot.State>
  ) {
    this.jwt$ = this.store.pipe(select(fromRoot.getJwt));
  }

  /**
   * AWS SDK S3インスタンスの取得
   *
   * @private
   * @returns {S3}
   * @memberof S3Service
   */
  private getS3(): Observable<S3> {
    return this.jwt$.pipe(
      switchMap((jwt: string) => this.cognitoUtil.buildCognitoCreds(jwt)),
      tap(creds =>
        AWS.config.update({
          credentials: creds,
          region: environment.region
        })
      ),
      map(creds => {
        const clientParams: ClientConfiguration = {
          region: environment.region,
          apiVersion: '2006-03-01',
          params: {
            Bucket: this.tenantUtil.uploadBucket
          }
        };
        return new S3(clientParams);
      })
    );
  }

  /**
   * ファイルアップロード
   *
   * @param {File} data
   * @param {string} [key]
   * @returns {Observable<S3.Types.PutObjectOutput>}
   * @memberof S3Service
   */
  public uploadData(
    data: File,
    key?: string
  ): Observable<S3.Types.ManagedUpload.SendData> {
    // アップロード時のパラメータ作成
    const params: S3.Types.PutObjectRequest = {
      Bucket: this.tenantUtil.uploadBucket,
      Key: key || data.name,
      ContentType: data.type,
      Body: data,
      StorageClass: 'STANDARD',
      ACL: 'private'
    };

    return this.getS3().pipe(
      flatMap((s3: S3) => this.upload(s3, params).pipe(retry(3))),
      take(1)
    );
  }

  /**
   * AWS SDKのメソッドをObservableでラップ
   *
   * @private
   * @param {S3} s3
   * @param {S3.Types.PutObjectRequest} params
   * @returns {Observable<S3.Types.ManagedUpload.SendData>}
   * @memberof S3Service
   */
  private upload(
    s3: S3,
    params: S3.Types.PutObjectRequest
  ): Observable<S3.Types.ManagedUpload.SendData> {
    return Observable.create((observer: Observer<any>) => {
      s3.upload(params, (error, sendData) => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(sendData);
        }
      });
    });
  }
}
