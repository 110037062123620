import { Injectable } from '@angular/core';
import { Observable, forkJoin, timer } from 'rxjs';
import { delayWhen, map, retryWhen, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { SearchService } from './../services/search.service';
import { Post } from './../models/post.model';

import * as fromRoot from './../reducers';
import * as loading from './../core/actions/loading.actions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  /**
   * Creates an instance of PostsService.
   * @param {ApiService} apiService
   * @param {SearchService} searchService
   * @param {LoadingService} loadingService
   * @memberof PostsService
   */
  constructor(
    private apiService: ApiService,
    private searchService: SearchService,
    private store: Store<fromRoot.State>
  ) {}

  /**
   * 投稿取得
   *
   * @param {string} postId
   * @returns {Promise<Detail>}
   * @memberof PostsService
   */
  public fetch(postId: string): Observable<Post> {
    return this.searchService.searchOne(postId);
  }

  /**
   * 新規投稿
   *
   * @param {*} params
   * @returns {Observable<Posts>}
   * @memberof PostsService
   */
  public post(params: Post): Observable<Post> {
    return this.apiService.post<Post>('/posts', params);
  }

  public multiplePost(params: Post[]): Observable<Post[]> {
    const requests: Observable<Post>[] = [];

    for (const param of params) {
      requests.push(this.post(param));
    }

    return forkJoin(requests);
  }

  /**
   * 投稿編集
   *
   * @param {string} postId
   * @param {*} params
   * @returns {Observable<Posts>}
   * @memberof PostsService
   */
  public update(postId: string, params: Post): Observable<Post> {
    return this.apiService.put<Post>(`/posts/${postId}`, params);
  }

  /**
   * 投稿削除
   *
   * @param {string} postId
   * @returns {Observable<Posts>}
   * @memberof PostsService
   */
  public delete(postId: string): Observable<string> {
    return this.apiService
      .delete<{ post_id: string }>(`/posts/${postId}`)
      .pipe(map(res => res.post_id));
  }

  /**
   * 投稿画像ダウンロードキュー
   *
   * @param {string} postId
   * @returns {Observable<string>}
   * @memberof PostsService
   */
  public download_queue(postId: string): Observable<string> {
    const postIds = { post_ids: [postId] };
    return this.apiService
      .post<{ signed_url: string }>(`/posts/download`, postIds)
      .pipe(map(res => res.signed_url));
  }
  /**
   * 投稿画像一括ダウンロードキュー
   *
   * @param {string[]} postIds
   * @returns {Observable<string>}
   * @memberof PostsService
   */
  public downloads_quene(postIds: string[]): Observable<string> {
    console.log(postIds);
    return this.apiService
      .post<{ signed_url: string }>(`/posts/download`, postIds)
      .pipe(map(res => res.signed_url));
  }

  /**
   * 投稿画像ダウンロード
   * 投稿画像一括ダウンロード
   *
   * @param {string} downloadId
   * @returns {Observable<string>}
   * @memberof PostsService
   */
  public download(
    downloadId: string,
    disableLoading?: boolean
  ): Observable<string> {
    const maxCount = 180;
    let tryCount = 1;
    const waitingSeconds = 5;
    return this.apiService
      .get<{ signed_url: string; message: string }>(
        `/posts/download/${downloadId}`
      )
      .pipe(
        tap(
          () =>
            !disableLoading && this.store.dispatch(new loading.StartAnimating())
        ),
        map(res => {
          // console.log('DL実行中:', tryCount * 5, '秒経過. ', res.signed_url);
          // 15分待ってもDLが始まらない時は、エラーが返される。
          if (tryCount > maxCount) {
            this.store.dispatch(new loading.StopAnimating());
            return 'error';
          } else if (res.signed_url === null) {
            tryCount += 1;

            // リトライするまでに待つ秒数
            throw waitingSeconds;
          }
          this.store.dispatch(new loading.StopAnimating());
          return res.signed_url;
        }),
        retryWhen(errors =>
          errors.pipe(
            //restart in 10 seconds
            delayWhen(val => timer(val * 1000))
          )
        )
      );
  }
}
